import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import { EditOutlined, LaptopOutlined, ReloadOutlined } from '@ant-design/icons';

import { Row, Col, Table, Button, Badge, Card } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import PickList01Actions from '../../Stores/PickList01/Actions';

import PickList01Dialog from './PickList01Dialog';

class PickList01Table extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.getExpandedColumns = this.getExpandedColumns.bind(this);
    this.showExpandedRow = this.showExpandedRow.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchPickList01,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchPickList01(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const { intl, sorts, filters, goToDocument } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'est_del_date' }),
        // sort field
        dataIndex: 'est_del_date',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'est_del_date'),
        // filter field
        key: 'est_del_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'est_del_date' }),
          'est_del_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.est_del_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'created' }),
        // sort field
        dataIndex: 'created_at',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'created_at'),
        // filter field
        key: 'created_at',
        render: (text, record) => <>{record.created_at}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'division' }),
        // sort field
        dataIndex: 'division_code',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'division_code'),
        // filter field
        key: 'division',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'division' }),
          'division',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.division_code}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'area' }),
        // sort field
        dataIndex: 'delivery_point_area_code',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'delivery_point_area_code'),
        // filter field
        key: 'delivery_point_area',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'area' }),
          'delivery_point_area',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <b>{record.delivery_point_area_code}</b>
            <br />
            {record.delivery_point_area_desc_01}
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'delivery_point' }),
        // sort field
        dataIndex: 'delivery_point_code',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'delivery_point_code'),
        // filter field
        key: 'delivery_point',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'delivery_point' }),
          'delivery_point',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <b>{record.delivery_point_code}</b>
            <br />
            {record.delivery_point_company_name_01}
          </>
        )
      },
      {
        width: 200,
        align: 'left',
        title: intl.formatMessage({ id: 'address' }),
        // sort field
        dataIndex: 'address',
        sorter: (a, b) => `${a.delivery_point_unit_no}`.localeCompare(b.delivery_point_unit_no),
        // filter field
        key: 'address',
        render: (text, record) => (
          <>
            {record.delivery_point_unit_no}
            {record.delivery_point_building_name ? (
              <>
                <br />
                {record.delivery_point_building_name}
              </>
            ) : (
              ''
            )}
            {record.delivery_point_street_name ? (
              <>
                <br />
                {record.delivery_point_street_name}
              </>
            ) : (
              ''
            )}
            {record.delivery_point_district_01 ? (
              <>
                <br />
                {record.delivery_point_district_01}
              </>
            ) : (
              ''
            )}
            {record.delivery_point_district_02 ? (
              <>
                <br />
                {record.delivery_point_district_02}
              </>
            ) : (
              ''
            )}
            {record.delivery_point_postcode ? (
              <>
                <br />
                {record.delivery_point_postcode}
              </>
            ) : (
              ''
            )}
            {record.delivery_point_state_name ? (
              <>
                <br />
                {record.delivery_point_state_name}
              </>
            ) : (
              ''
            )}
            {record.delivery_point_country_name ? (
              <>
                <br />
                {record.delivery_point_country_name}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'salesman' }),
        // sort field
        dataIndex: 'salesman_username',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'salesman_username'),
        // filter field
        key: 'salesman',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'salesman' }),
          'salesman',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <b>{record.salesman_username}</b>
          </>
        )
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'desc' }),
        // sort field
        dataIndex: 'desc_01',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'desc_01'),
        // filter field
        key: 'desc_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'desc' }),
          'desc_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.desc_01} <br />
            {record.desc_02} <br />
          </>
        )
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.doc_code}</div>
            <strong>
              {record.shipping_doc_code.length > 0 ? record.shipping_doc_code : <br />}
            </strong>
            {record.ref_code_01 ? (
              <>
                <br />
                {record.ref_code_01}
              </>
            ) : (
              ''
            )}
            {record.ref_code_02 ? (
              <>
                <br />
                {record.ref_code_02}
              </>
            ) : (
              ''
            )}
            <div>{record.str_doc_status}</div>
          </>
        )
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'brand' }),
        // sort field
        dataIndex: 'item_group_01_code',
        // filter field
        key: 'item_group_01',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'brand' }),
          'item_group_01',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            {record.item_group_01s.map(value => (
              <div key={value.id}>
                <b>{value.code}</b>
                <br />
                {value.desc_01}
              </div>
            ))}
          </>
        )
      },
      {
        fixed: 'right',
        width: 120,
        align: 'right',
        title: intl.formatMessage({ id: 'net_amt' }),
        // sort field
        dataIndex: 'net_amt',
        ...AntDesignTable.getColumnMultiSortProps(sorts, 'net_amt'),
        // filter field
        key: 'net_amt',
        render: (text, record) => (
          <>
            <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.net_amt)}
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.pallet_qty)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'pallet_qty' })}
              </b>
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_decimal_qty)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'case_decimal_qty' })}
              </b>
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.gross_weight)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'gross_weight' })}
              </b>
            </div>

            <div style={{ textAlign: 'right' }}>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.cubic_meter)}
              <b>
                &nbsp;
                {intl.formatMessage({ id: 'cubic_meter' })}
              </b>
            </div>
          </>
        )
      },
      {
        width: 50,
        fixed: 'right',
        title: '',
        // filter field
        key: 'item',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'item' }),
          'item',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => (
          <>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
          </>
        )
      }
    ];
  }

  getExpandedColumns() {
    const { intl } = this.props;

    return [
      {
        width: 30,
        align: 'left',
        title: '',
        // sort field
        dataIndex: 'line_no',
        // sorter: (a, b) => a.line_no - b.line_no,
        // defaultSortOrder: 'ascend',
        // filter field
        key: 'line_no',
        render: (text, record, index) => <>{index + 1}</>
      },
      {
        width: 200,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'desc_01',
        sorter: (a, b) => `${a.desc_01}`.localeCompare(b.desc_01),
        // filter field
        key: 'desc_01',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.item_code}</div>
            {record.desc_01}
            {record.desc_02 ? (
              <>
                <br />
                {record.desc_02}
              </>
            ) : (
              ''
            )}
            {record.desc_03 ? (
              <>
                <br />
                {record.desc_03}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'expiry_date' }),
        // sort field
        dataIndex: 'expiry_date',
        // filter field
        key: 'expiry_date',
        render: (text, record) => (
          <>
            <div style={{ fontWeight: 'bold' }}>{record.batch_serial_no}</div>
            {record.expiry_date ? <div>{record.expiry_date}</div> : ''}
            {record.receipt_date ? <>{record.receipt_date}</> : ''}
          </>
        )
      },
      {
        width: 90,
        align: 'right',
        title: intl.formatMessage({ id: 'case' }),
        // sort field
        dataIndex: 'case_qty',
        sorter: (a, b) => a.case_qty - b.case_qty,
        // filter field
        key: 'case_qty',
        render: (text, record) =>
          record.case_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.case_qty)}
              <br />
              {record.item_case_uom_code}
            </>
          ) : (
            ''
          )
      },
      {
        width: 90,
        align: 'right',
        title: intl.formatMessage({ id: 'loose' }),
        // sort field
        dataIndex: 'loose_qty',
        sorter: (a, b) => a.loose_qty - b.loose_qty,
        // filter field
        key: 'loose_qty',
        render: (text, record) =>
          record.loose_qty > 0 ? (
            <>
              {new Intl.NumberFormat([], {
                style: 'decimal',
                minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
              }).format(record.loose_qty)}
              <br />
              {record.item_loose_uom_code}
            </>
          ) : (
            ''
          )
      }
    ];
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  showExpandedRow(record) {
    const { fetchIsLoading } = this.props;

    return (
      <Card bordered={false} size="small">
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          bordered={false}
          pagination={{
            pageSize: 20
          }}
          columns={this.getExpandedColumns()}
          dataSource={record.details}
          loading={fetchIsLoading}
          // defaultExpandAllRows
          // scroll={{ x: 750 }}
        />
      </Card>
    );
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments,
      expandedRows,
      setExpandedRows
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'outbound_orders' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: record => ({
              // Column configuration not to be checked
              disabled: record.str_doc_status !== 'COMPLETE'
              // name: record.name,
            })
          }}
          onChange={this.useOnTableChange}
          expandedRowRender={this.showExpandedRow}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            setExpandedRows(rowKeys);
          }}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <PickList01Dialog />
      </Card>
    );
  }
}

PickList01Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToDocument: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func,

  curSiteFlowId: PropTypes.number,
  fetchPickList01: PropTypes.func
};

PickList01Table.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToDocument() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  expandedRows: [],
  setExpandedRows() {},

  curSiteFlowId: 0,
  fetchPickList01() {}
};

const mapStateToProps = state => ({
  timestamp: state.pickList01.timestamp,
  workspaceIsVisible: state.pickList01.workspaceIsVisible,

  documents: state.pickList01.documents,
  selectedDocuments: state.pickList01.selectedDocuments,

  sorts: state.pickList01.sorts,
  filters: state.pickList01.filters,

  currentPage: state.pickList01.currentPage,
  pageSize: state.pickList01.pageSize,
  total: state.pickList01.total,
  fetchIsLoading: state.pickList01.fetchIsLoading,

  expandedRows: state.pickList01.expandedRows,

  curSiteFlowId: state.app.curSiteFlowId
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(PickList01Actions.pickList01ResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToDocument: hdrId => dispatch(PickList01Actions.pickList01GoToDocument(hdrId)),
  setWorkspaceVisible: boolean =>
    dispatch(PickList01Actions.pickList01SetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(PickList01Actions.pickList01AddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(PickList01Actions.pickList01RemoveSelectedDocuments(selectedDocuments)),

  fetchPickList01: (currentPage, sorts, filters, pageSize) =>
    dispatch(PickList01Actions.pickList01FetchPickList01(currentPage, sorts, filters, pageSize)),

  setExpandedRows: expandedRows =>
    dispatch(PickList01Actions.pickList01SetExpandedRows(expandedRows))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PickList01Table));
