import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  // Saga
  systemSettingFetchSetting: ['code'],
  systemSettingUpdateSetting: ['formikBag', 'newSetting'],
  systemSettingFetchCreditTerm: ['search'],

  // Reducer
  systemSettingResetTimestamp: null,
  systemSettingFetchSettingSuccess: ['data'],
  systemSettingSetLoading: ['isLoading'],
  systemSettingFetchCreditTermSuccess: ['creditTermOptions'],
  systemSettingFetchCreditTermLoading: ['creditTermLoading']
});

export const SystemSettingTypes = Types;
export default Creators;
