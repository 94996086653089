import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { SaveOutlined } from '@ant-design/icons';

import { Row, Col, Typography, Space } from 'antd';
import { FormikButton, FormikInput } from '../../Components/Formik';
import SystemSettingActions from '../../Stores/SystemSetting/Actions';

const { Text } = Typography;

class DefaultMinPalletForm extends React.PureComponent {
  constructor() {
    super();
  }

  componentDidMount() {
    const { systemSettingFetchSetting } = this.props;
    systemSettingFetchSetting('GDS_RCPT_DEFAULT_MIN_PALLET_QTY');
  }

  componentDidUpdate(prevProps) {
    const { systemSettingFetchSetting } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      systemSettingFetchSetting('GDS_RCPT_DEFAULT_MIN_PALLET_QTY');
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, data, systemSettingUpdateSetting } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          id: data?.id ?? 0,
          code: data?.code ?? 'GDS_RCPT_DEFAULT_MIN_PALLET_QTY',
          setting: data?.setting ?? 100
        }}
        onSubmit={(values, formikBag) => {
          // remove the submit_action field
          const processedValues = { ...values };
          delete processedValues.submit_action;
          systemSettingUpdateSetting(formikBag, processedValues);
        }}
      >
        {({ values, isSubmitting, handleSubmit }) => (
          <Form>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              <div>
                <Row type="flex" justify="center" gutter={[8, 8]}>
                  <Col span={5}>
                    <Text>{`${intl.formatMessage({
                      id: 'GDS_RCPT_DEFAULT_MIN_PALLET_QTY'
                    })}`}</Text>
                  </Col>
                  <Col span={1}>
                    <Text>:</Text>
                  </Col>
                  <Col span={18}>
                    <Field name="setting" component={FormikInput} />
                  </Col>
                </Row>
              </div>

              <Row type="flex" justify="end" gutter={[0, 16]}>
                <Col>
                  <Field
                    type="primary"
                    name="submit_action"
                    value="update"
                    component={FormikButton}
                    onClick={handleSubmit}
                    label={intl.formatMessage({
                      id: 'update'
                    })}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </Space>
          </Form>
        )}
      </Formik>
    );
  }
}

DefaultMinPalletForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  systemSettingUpdateSetting: PropTypes.func,
  systemSettingFetchSetting: PropTypes.func,
  data: PropTypes.object,
  isLoading: PropTypes.bool
};

DefaultMinPalletForm.defaultProps = {
  intl: {},
  systemSettingUpdateSetting() {},
  systemSettingFetchSetting() {},
  data: {},
  isLoading: false
};

const mapStateToProps = state => ({
  data: state.systemSetting.data,
  isLoading: state.systemSetting.appSettingIsLoading
});

const mapDispatchToProps = dispatch => ({
  systemSettingUpdateSetting: (formikBag, newDetail) =>
    dispatch(SystemSettingActions.systemSettingUpdateSetting(formikBag, newDetail)),
  systemSettingFetchSetting: code => dispatch(SystemSettingActions.systemSettingFetchSetting(code))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DefaultMinPalletForm));
