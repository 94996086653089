import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { IntlProvider, FormattedMessage } from 'react-intl';

import {
  AuditOutlined,
  DashboardOutlined,
  ExportOutlined,
  FolderOpenOutlined,
  ImportOutlined,
  MenuFoldOutlined,
  RocketOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SnippetsOutlined,
  TransactionOutlined,
  UserOutlined,
  ClusterOutlined,
  SendOutlined,
  ProjectOutlined,
  ThunderboltOutlined
} from '@ant-design/icons';

import { Row, Col, Layout, Menu, Dropdown, Button } from 'antd';
import { history } from '../../Stores/CreateStore';

import AppNavigator from '../../Navigators/AppNavigator';
import languageObject from '../../Translations';
import StartupActions from '../../Stores/Startup/Actions';
import SiteFlowSelect from './SiteFlowSelect';
import DivisionSelect from './DivisionSelect';
import AppActions from '../../Stores/App/Actions';

const { Header, Sider, Content, Footer } = Layout;

const { SubMenu } = Menu;

const menuKeys = {
  dashboard: '1',
  kanbanBoard: '1.1',
  slsOrdDetailBoard: '1.1.1',
  lspSlsOrdDetailBoard: '1.1.2',
  pickListDetailBoard: '1.1.3',
  loadListDetailBoard: '1.1.4',
  gdsRcptDetailBoard: '1.1.5',
  putAwayDetailBoard: '1.1.6',
  gdsRcptRtnDetailBoard: '1.1.7',
  putAwayRtnDetailBoard: '1.1.8',
  performance: '1.2',
  slsOrdFulfillmentChart: '1.2.1',
  lspSlsOrdFulfillmentChart: '1.2.2',
  whsePerformanceChart: '1.2.3',
  workerPerformanceChart: '1.2.4',
  workerReport: '1.3',
  workerPickListAnalysisReport: '1.3.1',
  workerLoadListAnalysisReport: '1.3.2',
  workerGdsRcptAnalysisReport: '1.3.3',
  workerPutAwayAnalysisReport: '1.3.4',
  workerPackListAnalysisReport: '1.3.5',

  sales: '2',
  debtorProcess: '2.1',
  deliveryPointProcess: '2.2',
  salesProcess: '2.3',
  slsRtnProcess: '2.4',
  salesReports: '2.5',
  outbOrdAnalysisReport: '2.5.1',
  slsOrdTrackingReport: '2.5.2',
  salesDocuments: '2.6',
  slsOrdIndex: '2.6.1',
  delOrdIndex: '2.6.2',
  slsInvIndex: '2.6.3',
  slsRtnIndex: '2.6.4',
  rtnRcptIndex: '2.6.5',

  purchase: '3',
  creditorProcess: '3.1',
  purchaseProcess: '3.2',
  purchaseReturnProcess: '3.3',
  purchaseDocuments: '3.4',
  advShipIndex: '3.4.1',
  purRtnIndex: '3.4.2',

  logisticService: '10',
  principalProcess: '10.1',
  lspDeliveryProcess: '10.2',
  lspReceivingProcess: '10.3',
  lspCustomerReturnProcess: '10.4',
  lspSupplierReturnProcess: '10.5',
  logisticReports: '10.6',
  lspSlsOrdTrackingReport: '10.6.1',
  logisticServiceDocuments: '10.7',
  lspSlsOrdIndex: '10.7.1',
  lspSlsRtnIndex: '10.7.2',
  lspAdvShipIndex: '10.7.3',
  lspDelOrdIndex: '10.7.4',

  manufacturing: '11',
  billOfMaterialsProcess: '11.1',
  planningProcess: '11.2',
  productionProcess: '11.3',
  manufacturingReport: '11.4',
  manufacturingDocuments: '11.5',
  prodOrdIndex: '11.5.1',
  prodIssIndex: '11.5.2',
  prodSheetIndex: '11.5.3',
  manufacturingItems: '11.6',
  uom: '11.7',
  layerFarm: '11.8',

  stockTransfer: '12',
  siteTrfProcess: '12.1',
  trfToStoreProcess: '12.2',
  trfFromStoreProcess: '12.3',
  trfToVanProcess: '12.4',
  trfFromVanProcess: '12.5',
  stockTransferReports: '12.6',
  transferOutTrackingReport: '12.6.1',
  transferInTrackingReport: '12.6.2',
  stockTransferDocuments: '12.7',
  trfOrdIndex: '12.7.1',
  stkIssIndex: '12.7.2',
  stkRcptIndex: '12.7.3',
  stkTrfIndex: '12.7.4',

  outbound: '4',
  gdsDelProcess: '4.1',
  rtnDelProcess: '4.2',
  outbOrdReports: '4.3',
  shipmentTrackingReport: '4.3.1',
  outboundExpiryReport: '4.3.2',
  outOfStockAnalysisReport: '4.3.3',
  outboundDocuments: '4.4',
  outbOrdIndex: '4.4.1',
  pickListIndex: '4.4.2',
  pickListWhseJobIndex: '4.4.3',
  loadListIndex: '4.4.4',
  loadListWhseJobIndex: '4.4.5',
  invDoc0102: '4.4.6',
  prfDelIndex: '4.4.7',
  gdsIssIndex: '4.4.8',
  packListIndex: '4.4.9',

  inbound: '5',
  gdsRcptProcess: '5.1',
  rtnRcptProcess: '5.2',
  inbOrdReports: '5.3',
  gdsRcptTrackingReport: '5.3.1',
  inboundDocuments: '5.4',
  inbOrdIndex: '5.4.1',
  gdsRcptIndex: '5.4.2',
  gdsRcptWhseJobIndex: '5.4.3',
  gdsRcptRtnIndex: '5.4.4',
  gdsRcptRtnWhseJobIndex: '5.4.5',
  putAwayIndex: '5.4.6',
  putAwayWhseJobIndex: '5.4.7',
  putAwayRtnIndex: '5.4.8',
  putAwayRtnWhseJobIndex: '5.4.9',

  internalMovement: '6',
  binTrfProcess: '6.1',
  invAuditProcess: '6.2',
  internalMovementReports: '6.3',
  stockBalanceReport: '6.3.1',
  stockCardReport: '6.3.2',
  stockAgingReport: '6.3.3',
  dailyStockMovementReport: '6.3.4',
  outbOrdReservedStockReport: '6.3.5',
  pickListReservedStockReport: '6.3.6',
  cycleCountAnalysisReport: '6.3.7',
  countAdjAnalysisReport: '6.3.8',
  stockReorderPlanningReport: '6.3.9',
  stockReorderPlanningReportV2: '6.3.10',
  internalMovementDocuments: '6.4',
  cycleCountIndex: '6.4.1',
  cycleCountWhseJobIndex: '6.4.2',
  countAdjIndex: '6.4.3',
  binTrfIndex: '6.4.4',
  binTrfWhseJobIndex: '6.4.5',

  warehouseSetup: '7',
  warehouseMap: '7.1',
  itemProcess: '7.2',
  storageBinProcess: '7.3',
  palletLabelProcess: '7.4',
  pickFaceStrategyProcess: '7.5',
  pickingCriteriaProcess: '7.6',
  transportProcess: '7.7',
  areaProcess: '7.8',
  countAdjReasonProcess: '7.9',

  settings: '8',
  userProcess: '8.1',
  roleProcess: '8.2',
  siteProcess: '8.3',
  companyProcess: '8.4',
  divisionProcess: '8.5',
  generalManagement: '8.6',
  appSetting: '8.7',
  shippingDocPrint: '8.8',
  gdsRcptSetting: '8.9',

  userMenu: '9',
  changePassword: '9.1',
  logout: '9.2'
};

const rootSubmenuKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

class RootScreen extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      collapsed: false,
      openKeys: [],
      selectedKeys: [],
      oldOpenKeys: []
    };

    this.useOnToggle = this.useOnToggle.bind(this);
    this.useOnOpenChange = this.useOnOpenChange.bind(this);
    this.useOnSelect = this.useOnSelect.bind(this);
  }

  componentDidMount() {
    const { pathname, startup } = this.props;
    const { location } = history;

    // Run the startup saga when the application is starting
    startup(pathname);

    const pathnameParts = location.pathname.split('/');
    // convert pathname to menukey
    const pathnameKey = pathnameParts.reduce((lastKey, path) => {
      if (menuKeys[path]) {
        return menuKeys[path];
      }
      return lastKey;
    }, '');
    // find out the openKeys based on menukey
    const pathnameOpenKeys = [];
    if (pathnameKey && pathnameKey.includes('.')) {
      const pathnameKeyParts = pathnameKey.split('.');
      pathnameKeyParts.forEach((part, index) => {
        let tmpOpenKey = '';
        for (let i = 0; i < index + 1; i += 1) {
          if (tmpOpenKey.length > 0) {
            tmpOpenKey += `.${pathnameKeyParts[i]}`;
          } else {
            tmpOpenKey += pathnameKeyParts[i];
          }
        }
        pathnameOpenKeys.push(tmpOpenKey);
      });
    }
    this.setState({ openKeys: pathnameOpenKeys, selectedKeys: [pathnameKey] });
  }

  useOnToggle() {
    const { collapsed, openKeys, oldOpenKeys } = this.state;

    if (collapsed === false) {
      this.setState({
        collapsed: !collapsed,
        oldOpenKeys: openKeys,
        openKeys: []
      });
    } else {
      this.setState({
        collapsed: !collapsed,
        openKeys: openKeys.length === 0 ? oldOpenKeys : openKeys
      });
    }
  }

  useOnOpenChange(openKeys) {
    const { openKeys: sOpenKeys } = this.state;

    // make sure open current submenu only
    const latestOpenKey = openKeys.find(key => sOpenKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      // this is current submenu
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  }

  useOnSelect(item) {
    this.setState({ selectedKeys: [item.key] });
  }

  render() {
    const { token, locale, appPath, user, logout } = this.props;
    const { collapsed, openKeys, selectedKeys } = this.state;

    const userMenu = (
      <Menu>
        <Menu.Item key={menuKeys.changePassword}>
          <Link to={`${appPath}/changePassword`}>
            <Button type="link">
              <FormattedMessage id="change_password" />
            </Button>
          </Link>
        </Menu.Item>
        <Menu.Item key={menuKeys.logout}>
          <Button type="link" onClick={logout}>
            <FormattedMessage id="logout" />
          </Button>
        </Menu.Item>
      </Menu>
    );

    return (
      <IntlProvider locale={locale} messages={languageObject[locale]}>
        <ConnectedRouter history={history}>
          <Layout>
            <Sider
              width={250}
              style={{ height: 600 }}
              trigger={null}
              collapsible
              collapsed={collapsed}
              theme="dark"
            >
              <div
                style={{
                  paddingLeft: '15px',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}
              >
                <img width="50px" src={`${appPath}/logo192.png`} alt="logo" />
              </div>
              <Menu
                openKeys={openKeys}
                onOpenChange={this.useOnOpenChange}
                onSelect={this.useOnSelect}
                theme="dark"
                mode="inline"
                inlineIndent={12}
                selectedKeys={selectedKeys}
              >
                <SubMenu
                  key={menuKeys.dashboard}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <DashboardOutlined />
                      <span>
                        <FormattedMessage id="dashboard" />
                      </span>
                    </>
                  }
                >
                  <SubMenu
                    key={menuKeys.kanbanBoard}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <ProjectOutlined />
                        <span>
                          <FormattedMessage id="kanban_board" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.slsOrdDetailBoard}>
                      <Link to={`${appPath}/slsOrdDetailBoard`}>
                        <FormattedMessage id="sales_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.lspSlsOrdDetailBoard}>
                      <Link to={`${appPath}/lspSlsOrdDetailBoard`}>
                        <FormattedMessage id="logistic_sales_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.pickListDetailBoard}>
                      <Link to={`${appPath}/pickListDetailBoard`}>
                        <FormattedMessage id="pick_list" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.loadListDetailBoard}>
                      <Link to={`${appPath}/loadListDetailBoard`}>
                        <FormattedMessage id="load_list" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.gdsRcptDetailBoard}>
                      <Link to={`${appPath}/gdsRcptDetailBoard`}>
                        <FormattedMessage id="goods_receipt" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.putAwayDetailBoard}>
                      <Link to={`${appPath}/putAwayDetailBoard`}>
                        <FormattedMessage id="put_away" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.gdsRcptRtnDetailBoard}>
                      <Link to={`${appPath}/gdsRcptRtnDetailBoard`}>
                        <FormattedMessage id="goods_receipt_return" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.putAwayRtnDetailBoard}>
                      <Link to={`${appPath}/putAwayRtnDetailBoard`}>
                        <FormattedMessage id="put_away_return" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={menuKeys.performance}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <ThunderboltOutlined />
                        <span>
                          <FormattedMessage id="performance" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.slsOrdFulfillmentChart}>
                      <Link to={`${appPath}/slsOrdFulfillmentChart`}>
                        <FormattedMessage id="sales_order_fulfillment" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.lspSlsOrdFulfillmentChart}>
                      <Link to={`${appPath}/lspSlsOrdFulfillmentChart`}>
                        <FormattedMessage id="logistic_order_fulfillment" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.whsePerformanceChart}>
                      <Link to={`${appPath}/whsePerformanceChart`}>
                        <FormattedMessage id="warehouse_performance" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.workerPerformanceChart}>
                      <Link to={`${appPath}/workerPerformanceChart`}>
                        <FormattedMessage id="worker_performance" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={menuKeys.workerReport}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <SnippetsOutlined />
                        <span>
                          <FormattedMessage id="worker_reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.workerPickListAnalysisReport}>
                      <Link to={`${appPath}/workerPickListAnalysisReport`}>
                        <FormattedMessage id="pick_list_analysis" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.workerLoadListAnalysisReport}>
                      <Link to={`${appPath}/workerLoadListAnalysisReport`}>
                        <FormattedMessage id="load_list_analysis" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.workerGdsRcptAnalysisReport}>
                      <Link to={`${appPath}/workerGdsRcptAnalysisReport`}>
                        <FormattedMessage id="gds_rcpt_analysis" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.workerPutAwayAnalysisReport}>
                      <Link to={`${appPath}/workerPutAwayAnalysisReport`}>
                        <FormattedMessage id="put_away_analysis" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.workerPackListAnalysisReport}>
                      <Link to={`${appPath}/workerPackListAnalysisReport`}>
                        <FormattedMessage id="pack_list_analysis" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
                <SubMenu
                  key={menuKeys.sales}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <TransactionOutlined />
                      <span>
                        <FormattedMessage id="sales" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.debtorProcess}>
                    <Link to={`${appPath}/debtorProcess/index/DEBTOR_LIST_01`}>
                      <FormattedMessage id="debtor" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.deliveryPointProcess}>
                    <Link to={`${appPath}/deliveryPointProcess/index/DELIVERY_POINT_LIST_01`}>
                      <FormattedMessage id="delivery_point" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.salesProcess}>
                    <Link to={`${appPath}/salesProcess/index/OUTB_ORD_01`}>
                      <FormattedMessage id="sales_and_billing" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.slsRtnProcess}>
                    <Link to={`${appPath}/slsRtnProcess/index/INB_ORD_02`}>
                      <FormattedMessage id="sales_return" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.salesReports}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <SnippetsOutlined />
                        <span>
                          <FormattedMessage id="reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.outbOrdAnalysisReport}>
                      <Link to={`${appPath}/outbOrdAnalysisReport`}>
                        <FormattedMessage id="outbound_order_analysis" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.slsOrdTrackingReport}>
                      <Link to={`${appPath}/slsOrdTrackingReport`}>
                        <FormattedMessage id="sales_order_tracking" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key={menuKeys.salesDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <FolderOpenOutlined />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.slsOrdIndex}>
                      <Link to={`${appPath}/slsOrdIndex`}>
                        <FormattedMessage id="sales_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.delOrdIndex}>
                      <Link to={`${appPath}/delOrdIndex`}>
                        <FormattedMessage id="delivery_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.slsInvIndex}>
                      <Link to={`${appPath}/slsInvIndex`}>
                        <FormattedMessage id="sales_invoice" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.slsRtnIndex}>
                      <Link to={`${appPath}/slsRtnIndex`}>
                        <FormattedMessage id="sales_return" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.rtnRcptIndex}>
                      <Link to={`${appPath}/rtnRcptIndex`}>
                        <FormattedMessage id="return_receipt" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
                <SubMenu
                  key={menuKeys.purchase}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <ShoppingCartOutlined />
                      <span>
                        <FormattedMessage id="purchase" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.creditorProcess}>
                    <Link to={`${appPath}/creditorProcess/index/CREDITOR_LIST_01`}>
                      <FormattedMessage id="creditor" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.purchaseProcess}>
                    <Link to={`${appPath}/purchaseProcess/index/INB_ORD_01`}>
                      <FormattedMessage id="purchasing" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.purchaseReturnProcess}>
                    <Link to={`${appPath}/purchaseReturnProcess/index/OUTB_ORD_08`}>
                      <FormattedMessage id="purchase_return" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.purchaseDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <FolderOpenOutlined />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.advShipIndex}>
                      <Link to={`${appPath}/advShipIndex`}>
                        <FormattedMessage id="adv_ship_notice" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.purRtnIndex}>
                      <Link to={`${appPath}/purRtnIndex`}>
                        <FormattedMessage id="purchase_return" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>

                <SubMenu
                  key={menuKeys.logisticService}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <RocketOutlined />
                      <span>
                        <FormattedMessage id="logistic_service" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.principalProcess}>
                    <Link to={`${appPath}/principalProcess/index/PRINCIPAL_LIST_01`}>
                      <FormattedMessage id="principal" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.lspDeliveryProcess}>
                    <Link to={`${appPath}/lspDeliveryProcess/index/OUTB_ORD_02`}>
                      <FormattedMessage id="delivery" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.lspReceivingProcess}>
                    <Link to={`${appPath}/lspReceivingProcess/index/INB_ORD_03`}>
                      <FormattedMessage id="receiving" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.lspCustomerReturnProcess}>
                    <Link to={`${appPath}/lspCustomerReturnProcess/index/INB_ORD_04`}>
                      <FormattedMessage id="customer_return" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.lspSupplierReturnProcess}>
                    <Link to={`${appPath}/lspSupplierReturnProcess/index/OUTB_ORD_03`}>
                      <FormattedMessage id="supplier_return" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key={menuKeys.logisticReports}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <SnippetsOutlined />
                        <span>
                          <FormattedMessage id="reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.lspSlsOrdTrackingReport}>
                      <Link to={`${appPath}/lspSlsOrdTrackingReport`}>
                        <FormattedMessage id="sales_order_tracking" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={menuKeys.logisticServiceDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <FolderOpenOutlined />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.lspSlsOrdIndex}>
                      <Link to={`${appPath}/lspSlsOrdIndex`}>
                        <FormattedMessage id="sales_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.lspSlsRtnIndex}>
                      <Link to={`${appPath}/lspSlsRtnIndex`}>
                        <FormattedMessage id="sales_return" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.lspAdvShipIndex}>
                      <Link to={`${appPath}/lspAdvShipIndex`}>
                        <FormattedMessage id="advanced_shipment_notice" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.lspDelOrdIndex}>
                      <Link to={`${appPath}/lspDelOrdIndex`}>
                        <FormattedMessage id="delivery_order" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>

                <SubMenu
                  key={menuKeys.manufacturing}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <ClusterOutlined />
                      <span>
                        <FormattedMessage id="manufacturing" />
                      </span>
                    </>
                  }
                >
                  {/*
                  <Menu.Item key={menuKeys.billOfMaterialsProcess}>
                    <Link to={`${appPath}/billOfMaterialsProcess`}>
                      <FormattedMessage id="bill_of_materials" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.planningProcess}>
                    <Link to={`${appPath}/planningProcess`}>
                      <FormattedMessage id="planning" />
                    </Link>
                  </Menu.Item>
                  */}
                  <Menu.Item key={menuKeys.productionProcess}>
                    <Link to={`${appPath}/productionProcess/index/OUTB_ORD_04`}>
                      <FormattedMessage id="production" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.manufacturingItems}>
                    <Link to={`${appPath}/itemManufactureProcess/index/FINISHED_ITEMS`}>
                      <FormattedMessage id="item" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.uom}>
                    <Link to={`${appPath}/uom/index`}>
                      <FormattedMessage id="uom" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.layerFarm}>
                    <Link to={`${appPath}/layerFarm/index/LAYER_FARM_LIST`}>
                      <FormattedMessage id="layer_farm" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key={menuKeys.manufacturingDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <FolderOpenOutlined />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.prodOrdIndex}>
                      <Link to={`${appPath}/prodOrdIndex`}>
                        <FormattedMessage id="production_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.prodIssIndex}>
                      <Link to={`${appPath}/prodIssIndex`}>
                        <FormattedMessage id="production_issue" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.prodSheetIndex}>
                      <Link to={`${appPath}/prodSheetIndex`}>
                        <FormattedMessage id="production_sheet" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>

                <SubMenu
                  key={menuKeys.stockTransfer}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <SendOutlined />
                      <span>
                        <FormattedMessage id="stock_transfer" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.siteTrfProcess}>
                    <Link to={`${appPath}/siteTrfProcess/index/OUTB_ORD_05`}>
                      <FormattedMessage id="site_transfer" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.trfToStoreProcess}>
                    <Link to={`${appPath}/trfToStoreProcess/index/OUTB_ORD_06`}>
                      <FormattedMessage id="transfer_to_store" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.trfFromStoreProcess}>
                    <Link to={`${appPath}/trfFromStoreProcess/index/INB_ORD_06`}>
                      <FormattedMessage id="transfer_from_store" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.trfToVanProcess}>
                    <Link to={`${appPath}/trfToVanProcess/index/OUTB_ORD_07`}>
                      <FormattedMessage id="transfer_to_van" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.trfFromVanProcess}>
                    <Link to={`${appPath}/trfFromVanProcess/index/INB_ORD_07`}>
                      <FormattedMessage id="transfer_from_van" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key={menuKeys.stockTransferReports}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <SnippetsOutlined />
                        <span>
                          <FormattedMessage id="reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.transferOutTrackingReport}>
                      <Link to={`${appPath}/transferOutTrackingReport`}>
                        <FormattedMessage id="transfer_out_tracking_report" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.transferInTrackingReport}>
                      <Link to={`${appPath}/transferInTrackingReport`}>
                        <FormattedMessage id="transfer_in_tracking_report" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={menuKeys.stockTransferDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <FolderOpenOutlined />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.trfOrdIndex}>
                      <Link to={`${appPath}/trfOrdIndex`}>
                        <FormattedMessage id="transfer_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.stkIssIndex}>
                      <Link to={`${appPath}/stkIssIndex`}>
                        <FormattedMessage id="stock_issue" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.stkRcptIndex}>
                      <Link to={`${appPath}/stkRcptIndex`}>
                        <FormattedMessage id="stock_receipt" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.stkTrfIndex}>
                      <Link to={`${appPath}/stkTrfIndex`}>
                        <FormattedMessage id="stock_transfer" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>

                <SubMenu
                  key={menuKeys.outbound}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <ExportOutlined />
                      <span>
                        <FormattedMessage id="outbound" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.gdsDelProcess}>
                    <Link to={`${appPath}/gdsDelProcess/index/PICK_LIST_01`}>
                      <FormattedMessage id="goods_delivery" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.rtnDelProcess}>
                    <Link to={`${appPath}/rtnDelProcess/index/GDS_ISS_01`}>
                      <FormattedMessage id="return_delivery" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.outbOrdReports}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <SnippetsOutlined />
                        <span>
                          <FormattedMessage id="reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.shipmentTrackingReport}>
                      <Link to={`${appPath}/shipmentTrackingReport`}>
                        <FormattedMessage id="shipment_tracking_report" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.outboundExpiryReport}>
                      <Link to={`${appPath}/outboundExpiryReport`}>
                        <FormattedMessage id="outbound_expiry_report" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.outOfStockAnalysisReport}>
                      <Link to={`${appPath}/outOfStockAnalysisReport`}>
                        <FormattedMessage id="out_of_stock_analysis_report" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key={menuKeys.outboundDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <FolderOpenOutlined />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.outbOrdIndex}>
                      <Link to={`${appPath}/outbOrdIndex`}>
                        <FormattedMessage id="outbound_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.pickListIndex}>
                      <Link to={`${appPath}/pickListIndex`}>
                        <FormattedMessage id="pick_list" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.pickListWhseJobIndex}>
                      <Link to={`${appPath}/whseJob03Index`}>
                        <FormattedMessage id="mobile_pick_list" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.loadListIndex}>
                      <Link to={`${appPath}/loadListIndex`}>
                        <FormattedMessage id="load_list" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.loadListWhseJobIndex}>
                      <Link to={`${appPath}/whseJob06Index`}>
                        <FormattedMessage id="mobile_load_list" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.invDoc0102}>
                      <Link to={`${appPath}/gdsDelProcess/index/INV_DOC_01_02`}>
                        <FormattedMessage id="reprint_shipping_document" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.prfDelIndex}>
                      <Link to={`${appPath}/prfDelIndex`}>
                        <FormattedMessage id="proof_of_delivery" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.gdsIssIndex}>
                      <Link to={`${appPath}/gdsIssIndex`}>
                        <FormattedMessage id="goods_issue" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.packListIndex}>
                      <Link to={`${appPath}/packListIndex`}>
                        <FormattedMessage id="pack_list" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>

                <SubMenu
                  key={menuKeys.inbound}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <ImportOutlined />
                      <span>
                        <FormattedMessage id="inbound" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.gdsRcptProcess}>
                    <Link to={`${appPath}/gdsRcptProcess/index/GDS_RCPT_01`}>
                      <FormattedMessage id="goods_receipt" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.rtnRcptProcess}>
                    <Link to={`${appPath}/rtnRcptProcess/index/GDS_RCPT_02`}>
                      <FormattedMessage id="return_receipt" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.inbOrdReports}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <SnippetsOutlined />
                        <span>
                          <FormattedMessage id="reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.gdsRcptTrackingReport}>
                      <Link to={`${appPath}/gdsRcptTrackingReport`}>
                        <FormattedMessage id="goods_receipt_tracking_report" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key={menuKeys.inboundDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <FolderOpenOutlined />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.inbOrdIndex}>
                      <Link to={`${appPath}/inbOrdIndex`}>
                        <FormattedMessage id="inbound_order" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.gdsRcptIndex}>
                      <Link to={`${appPath}/gdsRcptIndex`}>
                        <FormattedMessage id="goods_receipt" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.gdsRcptWhseJobIndex}>
                      <Link to={`${appPath}/whseJob1401Index`}>
                        <FormattedMessage id="mobile_goods_receipt" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.gdsRcptRtnIndex}>
                      <Link to={`${appPath}/gdsRcptRtnIndex`}>
                        <FormattedMessage id="goods_receipt_return" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.gdsRcptRtnWhseJobIndex}>
                      <Link to={`${appPath}/whseJob1402Index`}>
                        <FormattedMessage id="mobile_goods_receipts_return" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.putAwayIndex}>
                      <Link to={`${appPath}/putAwayIndex`}>
                        <FormattedMessage id="put_away" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.putAwayWhseJobIndex}>
                      <Link to={`${appPath}/whseJob1501Index`}>
                        <FormattedMessage id="mobile_put_away" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.putAwayRtnIndex}>
                      <Link to={`${appPath}/putAwayRtnIndex`}>
                        <FormattedMessage id="put_away_return" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.putAwayRtnWhseJobIndex}>
                      <Link to={`${appPath}/whseJob1502Index`}>
                        <FormattedMessage id="mobile_put_away_return" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>

                <SubMenu
                  key={menuKeys.internalMovement}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <AuditOutlined />
                      <span>
                        <FormattedMessage id="internal_movement" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.binTrfProcess}>
                    <Link to={`${appPath}/binTrfProcess/index/WHSE_JOB_17_02`}>
                      <FormattedMessage id="bin_to_bin_transfer" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.invAuditProcess}>
                    <Link to={`${appPath}/invAuditProcess/index/WHSE_JOB_16_01`}>
                      <FormattedMessage id="inventory_audit" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key={menuKeys.internalMovementReports}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <SnippetsOutlined />
                        <span>
                          <FormattedMessage id="reports" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.stockBalanceReport}>
                      <Link to={`${appPath}/stockBalanceReport`}>
                        <FormattedMessage id="stock_balance" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.stockCardReport}>
                      <Link to={`${appPath}/stockCardReport`}>
                        <FormattedMessage id="stock_card" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.stockAgingReport}>
                      <Link to={`${appPath}/stockAgingReport`}>
                        <FormattedMessage id="stock_aging" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.stockReorderPlanningReport}>
                      <Link to={`${appPath}/stockReorderPlanningReport`}>
                        <FormattedMessage id="stock_reorder_planning" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.stockReorderPlanningReportV2}>
                      <Link to={`${appPath}/stockReorderPlanningReportV2`}>
                        <FormattedMessage id="stock_reorder_planning_v2" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.dailyStockMovementReport}>
                      <Link to={`${appPath}/dailyStockMovementReport`}>
                        <FormattedMessage id="daily_stock_movement" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.outbOrdReservedStockReport}>
                      <Link to={`${appPath}/outbOrdReservedStockReport`}>
                        <FormattedMessage id="outbound_order_reserved_stock" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.pickingAllocErrorReport}>
                      <Link to={`${appPath}/pickingAllocErrorReport`}>
                        <FormattedMessage id="picking_allocation_error" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.pickListReservedStockReport}>
                      <Link to={`${appPath}/pickListReservedStockReport`}>
                        <FormattedMessage id="pick_list_reserved_stock" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.cycleCountAnalysisReport}>
                      <Link to={`${appPath}/cycleCountAnalysisReport`}>
                        <FormattedMessage id="cycle_count_analysis" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.countAdjAnalysisReport}>
                      <Link to={`${appPath}/countAdjAnalysisReport`}>
                        <FormattedMessage id="count_adjustment_analysis" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key={menuKeys.internalMovementDocuments}
                    title={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <FolderOpenOutlined />
                        <span>
                          <FormattedMessage id="documents" />
                        </span>
                      </>
                    }
                  >
                    <Menu.Item key={menuKeys.cycleCountIndex}>
                      <Link to={`${appPath}/cycleCountIndex`}>
                        <FormattedMessage id="cycle_count" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.cycleCountWhseJobIndex}>
                      <Link to={`${appPath}/whseJob16Index`}>
                        <FormattedMessage id="mobile_cycle_count" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.countAdjIndex}>
                      <Link to={`${appPath}/countAdjIndex`}>
                        <FormattedMessage id="count_adjustment" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.binTrfIndex}>
                      <Link to={`${appPath}/binTrfIndex`}>
                        <FormattedMessage id="bin_transfer" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={menuKeys.binTrfWhseJobIndex}>
                      <Link to={`${appPath}/whseJob17Index`}>
                        <FormattedMessage id="mobile_bin_transfer" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>

                <SubMenu
                  key={menuKeys.warehouseSetup}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <ShopOutlined />
                      <span>
                        <FormattedMessage id="warehouse_setup" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.warehouseMap}>
                    <Link to={`${appPath}/warehouseMap`}>
                      <FormattedMessage id="warehouse_map" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.itemProcess}>
                    <Link to={`${appPath}/itemProcess/index/ITEM_LIST_01`}>
                      <FormattedMessage id="item" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.storageBinProcess}>
                    <Link to={`${appPath}/storageBinProcess/index/STORAGE_BIN_LIST_01`}>
                      <FormattedMessage id="storage_bin" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.palletLabelProcess}>
                    <Link to={`${appPath}/palletLabelProcess/index/PALLET_LABEL_LIST_01`}>
                      <FormattedMessage id="pallet_label" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.pickFaceStrategyProcess}>
                    <Link
                      to={`${appPath}/pickFaceStrategyProcess/index/PICK_FACE_STRATEGY_LIST_01`}
                    >
                      <FormattedMessage id="pick_face_strategy" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.pickingCriteriaProcess}>
                    <Link to={`${appPath}/pickingCriteriaProcess/index/PICKING_CRITERIA_LIST_01`}>
                      <FormattedMessage id="picking_criteria" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.transportProcess}>
                    <Link to={`${appPath}/transportProcess/index/TRANSPORT_LIST_01`}>
                      <FormattedMessage id="transport" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.areaProcess}>
                    <Link to={`${appPath}/areaProcess/index/AREA_LIST_01`}>
                      <FormattedMessage id="area" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.countAdjReasonProcess}>
                    <Link to={`${appPath}/countAdjReasonProcess/index/COUNT_ADJ_REASON_LIST_01`}>
                      <FormattedMessage id="count_adj_reason" />
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key={menuKeys.settings}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <SettingOutlined />
                      <span>
                        <FormattedMessage id="settings" />
                      </span>
                    </>
                  }
                >
                  <Menu.Item key={menuKeys.shippingDocPrint}>
                    <Link to={`${appPath}/shippingDocPrint`}>
                      <FormattedMessage id="shipping_document" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.gdsRcptSetting}>
                    <Link to={`${appPath}/gdsRcptSetting`}>
                      <FormattedMessage id="gds_rcpt" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={menuKeys.userProcess}>
                    <Link to={`${appPath}/userProcess/index/USER_LIST_01`}>
                      <FormattedMessage id="user_management" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.roleProcess}>
                    <Link to={`${appPath}/roleProcess/index/ROLE_LIST_01`}>
                      <FormattedMessage id="role_management" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.siteProcess}>
                    <Link to={`${appPath}/siteProcess/index/SITE_LIST_01`}>
                      <FormattedMessage id="site_management" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.companyProcess}>
                    <Link to={`${appPath}/companyProcess/index/COMPANY_LIST_01`}>
                      <FormattedMessage id="company_management" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.divisionProcess}>
                    <Link to={`${appPath}/divisionProcess/index/DIVISION_LIST_01`}>
                      <FormattedMessage id="division_management" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.generalManagement}>
                    <Link to={`${appPath}/generalManagement/index/PROJECT_LIST_01`}>
                      <FormattedMessage id="general_management" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={menuKeys.appSetting}>
                    <Link to={`${appPath}/appSetting`}>
                      <FormattedMessage id="app_setting" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </Sider>
            <Layout>
              <Header style={{ background: '#fff', padding: 10 }}>
                <Row type="flex" justify="space-between" gutter={[8, 8]}>
                  <Col span={1}>
                    <MenuFoldOutlined onClick={this.useOnToggle} />
                  </Col>
                  <Col span={20}>
                    {token ? (
                      <>
                        <SiteFlowSelect />
                        <DivisionSelect />
                      </>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col span={3}>
                    {token ? (
                      <Dropdown overlay={userMenu} trigger={['click']}>
                        <Button type="link" icon={<UserOutlined />}>
                          {user.username}
                        </Button>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </Header>
              <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                <AppNavigator appPath={appPath} />
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                {`Version ${
                  process.env.REACT_APP_VERSION
                } ©${new Date().getFullYear()} LuuWu Solutions Sdn Bhd`}
              </Footer>
            </Layout>
          </Layout>
        </ConnectedRouter>
      </IntlProvider>
    );
  }
}

RootScreen.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string
  }),
  appPath: PropTypes.string,
  pathname: PropTypes.string,
  locale: PropTypes.string,
  token: PropTypes.string,
  startup: PropTypes.func,
  logout: PropTypes.func
};

RootScreen.defaultProps = {
  user: {
    username: ''
  },
  appPath: '',
  pathname: '',
  locale: '',
  token: '',
  startup() {},
  logout() {}
};

const mapStateToProps = state => ({
  user: state.app.user,
  pathname: state.router.location.pathname,
  locale: state.app.locale,
  appPath: state.app.appPath,
  token: state.app.token
});

const mapDispatchToProps = dispatch => ({
  startup: pathname => dispatch(StartupActions.startup(pathname)),
  logout: () => dispatch(AppActions.appLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(RootScreen);
