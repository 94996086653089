import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import SystemSettingActions, { SystemSettingTypes } from '../Stores/SystemSetting/Actions';

const getAppStore = state => state.app;

export function* systemSettingFetchSetting({ code }) {
  try {
    yield put(SystemSettingActions.systemSettingSetLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `systemSetting/show/${code}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(SystemSettingActions.systemSettingFetchSettingSuccess(result.data.data));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(SystemSettingActions.systemSettingSetLoading(false));
  }
}

export function* systemSettingUpdateSetting({ formikBag, newSetting }) {
  formikBag.setSubmitting(true);
  yield put(SystemSettingActions.systemSettingSetLoading(true));
  try {
    const app = yield select(getAppStore);
    const postData = {
      data: newSetting
    };

    const result = yield call(
      ApiService.putApi, // function
      app.apiUrl,
      `systemSetting/update`,
      app.token,
      postData // params
    );
    if (result.isSuccess === true) {
      yield call(notification.success, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10)
      });
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(SystemSettingActions.systemSettingSetLoading(false));
  }
}

export function* systemSettingFetchCreditTerm({ search }) {
  try {
    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `creditTerm/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code}`
      }));

      yield put(SystemSettingActions.systemSettingFetchCreditTermSuccess(options));
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
  }
}

export const saga = [
  takeLatest(SystemSettingTypes.SYSTEM_SETTING_FETCH_SETTING, systemSettingFetchSetting),
  takeLatest(SystemSettingTypes.SYSTEM_SETTING_UPDATE_SETTING, systemSettingUpdateSetting),
  takeLatest(SystemSettingTypes.SYSTEM_SETTING_FETCH_CREDIT_TERM, systemSettingFetchCreditTerm)
];
