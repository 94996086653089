import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';

import { SaveOutlined } from '@ant-design/icons';

import { Card, Row, Col, Typography, Space } from 'antd';
import { FormikButton, FormikInput } from '../../Components/Formik';
import AppSettingActions from '../../Stores/AppSetting/Actions';

const { Text } = Typography;

class AppSettingScreen extends React.PureComponent {
  componentDidMount() {
    const { fetchAppSetting } = this.props;
    fetchAppSetting();
  }

  componentDidUpdate(prevProps) {
    const { fetchAppSetting } = this.props;

    const { changed } = treeChanges(prevProps, this.props);
    if (changed('timestamp')) {
      fetchAppSetting();
    }
  }

  componentWillUnmount() {}

  render() {
    const { intl, appSettingData, updateAppSetting } = this.props;

    return (
      <Card title={intl.formatMessage({ id: 'cps_app_update_link' })}>
        <Formik
          enableReinitialize
          initialValues={appSettingData}
          onSubmit={(values, formikBag) => {
            // remove the submit_action field
            const processedValues = { ...values };
            delete processedValues.submit_action;
            console.log(values);

            updateAppSetting(formikBag, processedValues);
          }}
        >
          {({ values, isSubmitting, handleSubmit }) => (
            <Form>
              <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <div>
                  <Row type="flex" justify="center" gutter={[8, 8]}>
                    <Col span={4}>
                      <Text>{intl.formatMessage({ id: 'cps_mobile_app_ver' })}</Text>
                    </Col>
                    <Col span={1}>
                      <Text>:</Text>
                    </Col>
                    <Col span={18}>
                      <Field name="cps_ver" component={FormikInput} />
                    </Col>
                  </Row>
                  <Row type="flex" justify="center" gutter={[8, 8]}>
                    <Col span={4}>
                      <Text>{intl.formatMessage({ id: 'cps_mobile_app_link' })}</Text>
                    </Col>
                    <Col span={1}>
                      <Text>:</Text>
                    </Col>
                    <Col span={18}>
                      <Field name="cps" component={FormikInput} />
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row type="flex" justify="center" gutter={[8, 8]}>
                    <Col span={4}>
                      <Text>{intl.formatMessage({ id: 'cps_wms_app_ver' })}</Text>
                    </Col>
                    <Col span={1}>
                      <Text>:</Text>
                    </Col>
                    <Col span={18}>
                      <Field name="wms_ver" component={FormikInput} />
                    </Col>
                  </Row>
                  <Row type="flex" justify="center" gutter={[8, 8]}>
                    <Col span={4}>
                      <Text>{intl.formatMessage({ id: 'cps_wms_app_link' })}</Text>
                    </Col>
                    <Col span={1}>
                      <Text>:</Text>
                    </Col>
                    <Col span={18}>
                      <Field name="wms" component={FormikInput} />
                    </Col>
                  </Row>
                </div>

                <Row type="flex" justify="end" gutter={[0, 16]}>
                  <Col>
                    <Field
                      type="primary"
                      name="submit_action"
                      value="update"
                      component={FormikButton}
                      onClick={handleSubmit}
                      label={intl.formatMessage({
                        id: 'update'
                      })}
                      icon={<SaveOutlined />}
                    />
                  </Col>
                </Row>
              </Space>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }
}

AppSettingScreen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  updateAppSetting: PropTypes.func,
  fetchAppSetting: PropTypes.func,
  appSettingData: PropTypes.object,
  isLoading: PropTypes.bool
};

AppSettingScreen.defaultProps = {
  intl: {},
  updateAppSetting() {},
  fetchAppSetting() {},
  appSettingData: {},
  isLoading: []
};

const mapStateToProps = state => ({
  isLoading: state.appSetting.appSettingIsLoading,
  appSettingData: state.appSetting.appSettingData
});

const mapDispatchToProps = dispatch => ({
  updateAppSetting: (formikBag, newDetail) =>
    dispatch(AppSettingActions.appSettingUpdateAppSetting(formikBag, newDetail)),
  fetchAppSetting: () => dispatch(AppSettingActions.appSettingFetchAppSetting())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AppSettingScreen));
