import React from 'react';
import { SpringValue, animated, to } from 'react-spring';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

class CustomBar extends React.PureComponent {
  constructor() {
    super();
    this.formatData = this.formatData.bind(this);
    this.calculateOffset = this.calculateOffset.bind(this);
    this.getCustomToolTipHTML = this.getCustomToolTipHTML.bind(this);
    this.getSvgFillByJobType = this.getSvgFillByJobType.bind(this);
    this.onMouseHoverHandler = this.onMouseHoverHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  onMouseHoverHandler(event, action, procType, jobTypeData, jobType) {
    const { tooltipRef, unit } = this.props;
    const xPos = event.pageX;
    const yPos = event.pageY;
    const xPosCenter = xPos - 320;
    const yPosCenter = yPos - 280;

    if (!tooltipRef.current) {
      return;
    }

    tooltipRef.current.style.transform = `translate(${xPosCenter}px, ${yPosCenter}px)`;

    if (action === 'move') {
      return;
    }
    const amount = jobTypeData[procType][unit];

    tooltipRef.current.style.display = 'inline-flex';
    tooltipRef.current.innerHTML = this.getCustomToolTipHTML(jobType, procType, amount);
  }

  // eslint-disable-next-line class-methods-use-this
  onMouseLeaveHandler() {
    const { tooltipRef } = this.props;

    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getCustomToolTipHTML(jobType, procType, value) {
    const { intl } = this.props;

    return `<span><strong>${intl.formatMessage({
      id: jobType
    })} </strong> <span>${procType}</span>: <strong>${value}</strong></span>`;
  }

  // eslint-disable-next-line class-methods-use-this
  getSvgFillByJobType(procType, jobType) {
    const lightness = procType === 'rtn' ? '30%' : '60%';
    const gdsRcpt = `hsl(205, 71%, ${lightness})`;
    const putAway = `hsl(28, 100%, ${lightness})`;
    const pickList = `#2ca02c`;
    const loadList = `#d62728`;

    if (jobType === 'gds_rcpt') return gdsRcpt;
    if (jobType === 'put_away') return putAway;
    if (jobType === 'pick_list') return pickList;
    if (jobType === 'load_list') return loadList;
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  calculateOffset(y, jobType) {
    return jobType === 'gds_rcpt' || jobType === 'pick_list' ? y - 15 : y - 30;
  }

  // eslint-disable-next-line class-methods-use-this
  formatData(data) {
    const str = data.toString().split('.');
    if (str[0].length >= 3) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }

    return str.join('.');
  }

  render() {
    const { customBarProps, unit, visibleAverageKeys, heightToValueRatio, numOfDates } = this.props;

    const { bar, style } = customBarProps;
    const springHeightReg = style.height;

    const { x, y, width, height, data } = bar;
    const { detailedData } = data.data;
    const whseJobType = data.id;
    const detailedDataByJobType = detailedData[whseJobType];

    const performanceValue = data.value;

    // add bar chart offset
    const X = x - 15;
    const regProcHeight = height;
    const rtnProcHeight = detailedDataByJobType.rtn[unit] * heightToValueRatio;
    const regProcYValue = y + height - regProcHeight;
    const rtnProcYValue = y + height - rtnProcHeight;

    const springHeightRtn = new SpringValue(0);
    springHeightRtn.key = 'height';
    springHeightRtn.start({ to: { height: rtnProcHeight } });

    // extract average data
    const avgGdsRcpt01 = detailedData.avg_gds_rcpt.reg[unit];
    const avgGdsRcpt01YValue =
      heightToValueRatio > 0 && bar.data.id === 'gds_rcpt'
        ? (performanceValue - avgGdsRcpt01) * heightToValueRatio + y
        : 0;

    const avgGdsRcpt02 = detailedData.avg_gds_rcpt.rtn[unit];
    const avgGdsRcpt02YValue =
      heightToValueRatio > 0 && bar.data.id === 'gds_rcpt'
        ? (performanceValue - avgGdsRcpt02) * heightToValueRatio + y
        : 0;

    const avgPutAway01 = detailedData.avg_put_away.reg[unit];
    const avgPutAway01YValue =
      heightToValueRatio > 0 && bar.data.id === 'put_away'
        ? (performanceValue - avgPutAway01) * heightToValueRatio + y
        : 0;

    const avgPutAway02 = detailedData.avg_put_away.rtn[unit];
    const avgPutAway02YValue =
      heightToValueRatio > 0 && bar.data.id === 'put_away'
        ? (performanceValue - avgPutAway02) * heightToValueRatio + y
        : 0;

    const avgPickList = detailedData.avg_pick_list.reg[unit];
    const avgPickListYValue =
      heightToValueRatio > 0 && bar.data.id === 'pick_list'
        ? (performanceValue - avgPickList) * heightToValueRatio + y
        : 0;

    const avgLoadList = detailedData.avg_load_list.reg[unit];
    const avgLoadListYValue =
      heightToValueRatio > 0 && bar.data.id === 'load_list'
        ? (performanceValue - avgLoadList) * heightToValueRatio + y
        : 0;

    // average line position - by trial and error
    const avgLineLength = 1.66 * 4 * numOfDates * width;

    // average line label positioning
    const avgLabelX = -30;
    const getAvgLabelY = avgLineYValue => avgLineYValue;

    const renderAvgLabelBox = avgLineYValue => {
      const strokeColor = this.getSvgFillByJobType('reg', whseJobType);
      return (
        <rect
          x={-69}
          y={avgLineYValue - 6}
          width="80"
          height="13"
          style={{ fill: 'rgb(255,255,255)', strokeWidth: 0.2, stroke: strokeColor }}
        />
      );
    };

    return (
      <g>
        <animated.rect
          transform={`rotate(180,${X + 0.5 * width}, ${regProcYValue + 0.5 * regProcHeight})`}
          fill={this.getSvgFillByJobType('reg', whseJobType)}
          height={to(springHeightReg, value => Math.max(value, 0))}
          width={width}
          x={X}
          y={regProcYValue}
          onMouseEnter={
            event =>
              this.onMouseHoverHandler(event, 'enter', 'reg', detailedDataByJobType, whseJobType)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onMouseMove={
            event =>
              this.onMouseHoverHandler(event, 'move', 'reg', detailedDataByJobType, whseJobType)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onMouseLeave={this.onMouseLeaveHandler}
        />
        <text
          x={X + width / 2}
          y={this.calculateOffset(y, whseJobType)}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: 12,
            pointerEvents: 'none',
            fill: 'black',
            opacity: 0.75
          }}
        >
          {data.value > 0 ? this.formatData(data.value) : null}
        </text>
        <animated.rect
          transform={`rotate(180,${X + 0.5 * width}, ${rtnProcYValue + 0.5 * rtnProcHeight})`}
          fill={this.getSvgFillByJobType('rtn', whseJobType)}
          height={to(springHeightRtn, value => Math.max(value, 0))}
          width={width}
          x={X}
          y={rtnProcYValue}
          onMouseEnter={
            event =>
              this.onMouseHoverHandler(event, 'enter', 'rtn', detailedDataByJobType, whseJobType)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onMouseMove={
            event =>
              this.onMouseHoverHandler(event, 'move', 'rtn', detailedDataByJobType, whseJobType)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onMouseLeave={this.onMouseLeaveHandler}
        />
        {avgLoadListYValue > 0 && visibleAverageKeys.includes('load_list') ? (
          <>
            <path
              d={`M${0.2 * width},${avgLoadListYValue}L${avgLineLength},${avgLoadListYValue}`}
              fill="none"
              stroke="hsl(0, 100%, 30%)"
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgLoadListYValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgLoadListYValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgLoadList)}
            </text>
          </>
        ) : null}
        {avgPickListYValue > 0 && visibleAverageKeys.includes('pick_list') ? (
          <>
            <path
              d={`M${0.2 * width},${avgPickListYValue}L${avgLineLength},${avgPickListYValue}`}
              fill="none"
              stroke="hsl(120, 100%, 30%)"
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgPickListYValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgPickListYValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgPickList)}
            </text>
          </>
        ) : null}
        {avgPutAway02YValue > 0 && visibleAverageKeys.includes('put_away_02') ? (
          <>
            <path
              d={`M${0.2 * width},${avgPutAway02YValue}L${avgLineLength},${avgPutAway02YValue}`}
              fill="none"
              stroke="hsl(28, 100%, 30%)"
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgPutAway02YValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgPutAway02YValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgPutAway02)}
            </text>
          </>
        ) : null}
        {avgPutAway01YValue > 0 && visibleAverageKeys.includes('put_away_01') ? (
          <>
            <path
              d={`M${0.2 * width},${avgPutAway01YValue}L${avgLineLength},${avgPutAway01YValue}`}
              fill="none"
              stroke="hsl(28, 100%, 30%)"
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgPutAway01YValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgPutAway01YValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgPutAway01)}
            </text>
          </>
        ) : null}
        {avgGdsRcpt02YValue > 0 && visibleAverageKeys.includes('gds_rcpt_02') ? (
          <>
            <path
              d={`M${0.2 * width},${avgGdsRcpt02YValue}L${avgLineLength},${avgGdsRcpt02YValue}`}
              fill="none"
              stroke="hsl(205, 100%, 30%)"
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgGdsRcpt02YValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgGdsRcpt02YValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgGdsRcpt02)}
            </text>
          </>
        ) : null}
        {avgGdsRcpt01YValue > 0 && visibleAverageKeys.includes('gds_rcpt_01') ? (
          <>
            <path
              d={`M${0.2 * width},${avgGdsRcpt01YValue}L${avgLineLength},${avgGdsRcpt01YValue}`}
              fill="none"
              stroke="hsl(205, 100%, 30%)"
              strokeDasharray="5,5"
            />
            {renderAvgLabelBox(avgGdsRcpt01YValue)}
            <text
              x={avgLabelX}
              y={getAvgLabelY(avgGdsRcpt01YValue)}
              textAnchor="middle"
              dominantBaseline="central"
              style={{
                fontSize: 12,
                pointerEvents: 'none',
                fill: 'black',
                opacity: 1
              }}
            >
              {this.formatData(avgGdsRcpt01)}
            </text>
          </>
        ) : null}
      </g>
    );
  }
}

CustomBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipRef: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  customBarProps: PropTypes.object,
  unit: PropTypes.string,
  visibleAverageKeys: PropTypes.arrayOf(PropTypes.string),
  heightToValueRatio: PropTypes.number,
  numOfDates: PropTypes.number
};

CustomBar.defaultProps = {
  intl: {},
  tooltipRef: {},
  customBarProps: {},
  unit: '',
  visibleAverageKeys: [],
  heightToValueRatio: 0,
  numOfDates: 0
};

const mapStateToProps = state => ({
  visibleAverageKeys: state.whsePerformanceChart.whsePerformanceChartVisibleAverageKeys
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomBar));
