import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import treeChanges from 'tree-changes';
import moment from 'moment';

import {
  AuditOutlined,
  BarcodeOutlined,
  EditOutlined,
  PrinterOutlined,
  ReloadOutlined,
  LaptopOutlined
} from '@ant-design/icons';

import { Row, Col, Table, Button, Card, Popconfirm, Tag, Badge, Popover } from 'antd';

import AntDesignTable from '../../Components/AntDesignTable';
import GdsRcptIndexActions from '../../Stores/GdsRcptIndex/Actions';
import GdsRcptIndexDialog from './GdsRcptIndexDialog';

class GdsRcptIndexTable extends React.PureComponent {
  constructor() {
    super();

    this.useOnTableChange = this.useOnTableChange.bind(this);
    this.useShowWorkspace = this.useShowWorkspace.bind(this);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getDocumentColumns = this.getDocumentColumns.bind(this);
    this.getExpandedColumns = this.getExpandedColumns.bind(this);
    this.showExpandedRow = this.showExpandedRow.bind(this);
  }

  componentDidMount() {
    const { curSiteFlowId, setWorkspaceVisible, resetTimestamp } = this.props;

    if (curSiteFlowId > 0) {
      setWorkspaceVisible(false);
      resetTimestamp();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      curSiteFlowId,
      resetTimestamp,
      fetchGdsRcptIndex,
      currentPage,
      sorts,
      filters,
      pageSize
    } = this.props;

    const { changed } = treeChanges(prevProps, this.props);

    if (changed('curSiteFlowId')) {
      resetTimestamp();
    }

    if (changed('timestamp')) {
      if (curSiteFlowId > 0) {
        fetchGdsRcptIndex(currentPage, sorts, filters, pageSize);
      }
    }
  }

  componentWillUnmount() {}

  handleSearch(selectedKeys, confirm) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    confirm();
  }

  handleReset(clearFilters) {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;

    clearFilters();
  }

  getDocumentColumns() {
    const {
      intl,
      sorts,
      filters,
      goToPrint,
      goToPrintHandlingUnit,
      goToDocument,
      goToAudit
    } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_code' }),
        // sort field
        dataIndex: 'doc_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_code'),
        // filter field
        key: 'doc_code',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'doc_code' }),
          'doc_code',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.doc_code
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'inb_ref_code_02' }),
        // sort field
        dataIndex: 'inb_ref_code_02',
        ...AntDesignTable.getColumnSortProps(sorts, 'inb_ref_code_02'),
        // filter field
        key: 'inb_ref_code_02',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'inb_ref_code_02' }),
          'inb_ref_code_02',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => record.inb_ord_hdr.ref_code_02
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_date' }),
        // sort field
        dataIndex: 'doc_date',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_date'),
        // filter field
        key: 'doc_date',
        ...AntDesignTable.getColumnSearchDateProps(
          filters,
          intl.formatMessage({ id: 'doc_date' }),
          'doc_date',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{moment(record.doc_date).format('YYYY-MM-DD')}</>
      },
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'doc_status' }),
        // sort field
        dataIndex: 'doc_status',
        ...AntDesignTable.getColumnSortProps(sorts, 'doc_status'),
        // filter field
        ...AntDesignTable.getColumnSearchDocStatusProps(filters, 'doc_status'),
        render: (text, record) => (
          <>
            <div>{record.str_doc_status}</div>
            {record.doc_flows.map(docData =>
              docData.doc_id === 0 ? (
                <Popover content={docData.doc_date} key={docData.doc_code}>
                  <Tag color="gold">{docData.doc_code}</Tag>
                </Popover>
              ) : (
                ''
              )
            )}
          </>
        )
      },
      {
        width: 90,
        align: 'left',
        title: intl.formatMessage({ id: 'to_storage_bin' }),
        // sort field
        dataIndex: 'to_storage_bin_code',
        // filter field
        key: 'to_storage_bin',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'to_storage_bin' }),
          'to_storage_bin',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) => <>{record.to_storage_bin_code}</>
      },
      {
        width: 120,
        align: 'left',
        title: intl.formatMessage({ id: 'purchaser' }),
        // sort field
        dataIndex: 'purchaser_username',
        // filter field
        key: 'purchaser',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'purchaser' }),
          'purchaser',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) =>
          record.inb_ord_hdr && <b>{record.inb_ord_hdr.purchaser_username}</b>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'biz_partner' }),
        // sort field
        dataIndex: 'biz_partner_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'biz_partner_code'),
        // filter field
        key: 'biz_partner',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'biz_partner' }),
          'biz_partner',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) =>
          record.inb_ord_hdr && (
            <>
              <b>{record.inb_ord_hdr.biz_partner_code}</b>
              <br />
              {record.inb_ord_hdr.biz_partner_company_name_01}
            </>
          )
      },
      {
        width: 150,
        // fixed: 'right',
        align: 'left',
        title: intl.formatMessage({ id: 'documents' }),
        // sort field
        dataIndex: 'inb_ord_hdr_code',
        ...AntDesignTable.getColumnSortProps(sorts, 'inb_ord_hdr_code'),
        // filter field
        key: 'inb_ord_hdr',
        ...AntDesignTable.getColumnSearchInputProps(
          filters,
          intl.formatMessage({ id: 'documents' }),
          'inb_ord_hdr',
          this.handleSearch,
          this.handleReset
        ),
        render: (text, record) =>
          record.inb_ord_hdr && (
            <div key={record.inb_ord_hdr.id} style={{ width: '100%', textAlign: 'right' }}>
              <div>
                <b>{record.inb_ord_hdr.shipping_doc_code}</b>
              </div>
              <div>
                <b>{record.inb_ord_hdr.ref_code_01}</b>
              </div>
              <div>
                {new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.inb_ord_hdr.net_amt)}
              </div>
            </div>
          )
      },

      {
        width: 90,
        fixed: 'right',
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_print' })}
              onConfirm={() => goToPrint(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
              disabled={record.doc_status !== 100}
            >
              <Button
                type="dashed"
                icon={<PrinterOutlined />}
                disabled={record.doc_status !== 100}
              />
            </Popconfirm>
            <Popconfirm
              placement="left"
              title={intl.formatMessage({ id: 'are_you_sure_to_print_pallet_label' })}
              onConfirm={() => goToPrintHandlingUnit(record)}
              onCancel={() => {}}
              okText={intl.formatMessage({ id: 'yes' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
              disabled={record.doc_status !== 100}
            >
              <Button
                type="dashed"
                icon={<BarcodeOutlined />}
                disabled={record.doc_status !== 100}
              />
            </Popconfirm>
            <Button type="dashed" icon={<EditOutlined />} onClick={() => goToDocument(record.id)} />
            <Button type="dashed" icon={<AuditOutlined />} onClick={() => goToAudit(record.id)} />
          </>
        )
      }
    ];
  }

  getExpandedColumns() {
    const { intl } = this.props;

    return [
      {
        width: 100,
        align: 'left',
        title: intl.formatMessage({ id: 'location' }),
        // sort field
        dataIndex: 'location_code',
        // filter field
        key: 'location_code',
        render: (text, record) => <>{record.location_code}</>
      },
      {
        width: 150,
        align: 'left',
        title: intl.formatMessage({ id: 'code' }),
        // sort field
        dataIndex: 'item_code',
        // filter field
        key: 'item_code',
        render: (text, record) => <>{record.item_code}</>
      },
      {
        width: 250,
        align: 'left',
        title: intl.formatMessage({ id: 'description' }),
        // sort field
        dataIndex: 'item_desc_01',
        // filter field
        key: 'item_desc_01',
        render: (text, record) => (
          <>
            {record.item_desc_01}
            {record.item_desc_02 ? (
              <>
                <br />
                {record.item_desc_02}
              </>
            ) : (
              ''
            )}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'order_qty' }),
        // sort field
        dataIndex: 'ord_item_unit_qty',
        // filter field
        key: 'ord_item_unit_qty',
        render: (text, record) => (
          <>
            {new Intl.NumberFormat([], {
              style: 'decimal',
              minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
              maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
            }).format(record.ord_item_unit_qty)}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'bad_qty' }),
        // sort field
        dataIndex: 'bad_item_unit_qty',
        // filter field
        key: 'bad_item_unit_qty',
        render: (text, record) => (
          <>
            {record.bad_item_unit_qty
              ? new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.bad_item_unit_qty)
              : ''}
          </>
        )
      },
      {
        width: 100,
        align: 'right',
        title: intl.formatMessage({ id: 'good_qty' }),
        // sort field
        dataIndex: 'good_item_unit_qty',
        // filter field
        key: 'good_item_unit_qty',
        render: (text, record) => (
          <>
            {record.good_item_unit_qty
              ? new Intl.NumberFormat([], {
                  style: 'decimal',
                  minimumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE,
                  maximumFractionDigits: process.env.REACT_APP_DECIMAL_SCALE
                }).format(record.good_item_unit_qty)
              : ''}
          </>
        )
      }
    ];
  }

  showExpandedRow() {
    const { expandedIsLoading, expandedDetails } = this.props;

    return (
      <Table
        size="small"
        // rowSelection={rowSelection}
        rowKey="id"
        bordered={false}
        pagination={{
          pageSize: 20
        }}
        columns={this.getExpandedColumns()}
        dataSource={expandedDetails}
        loading={expandedIsLoading}
        defaultExpandAllRows
        rowClassName={rowData => {
          if (rowData.is_tally !== true) {
            return 'error-row';
          }
          return '';
        }}
      />
    );
  }

  useOnTableChange(pagination, filters, sorter) {
    const { resetTimestamp } = this.props;

    const processedFilters = AntDesignTable.processFilters(filters);
    const processedSorts = AntDesignTable.processSorts(sorter);

    resetTimestamp(pagination.current, processedSorts, processedFilters, pagination.pageSize);
  }

  useShowWorkspace() {
    const { setWorkspaceVisible } = this.props;

    setWorkspaceVisible(true);
  }

  render() {
    const {
      intl,
      resetTimestamp,
      currentPage,
      pageSize,
      total,
      documents,
      fetchIsLoading,
      selectedDocuments,
      addSelectedDocuments,
      removeSelectedDocuments,
      expandedRows,
      setExpandedRows,
      fetchExpandedDetails
    } = this.props;

    return (
      <Card
        title={intl.formatMessage({ id: 'goods_receipts' })}
        bordered={false}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ padding: 0 }}
      >
        <Table
          size="small"
          // rowSelection={rowSelection}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: parseInt(pageSize, 10),
            total,
            showTotal: () => `${total} items`
          }}
          columns={this.getDocumentColumns()}
          dataSource={documents}
          loading={fetchIsLoading}
          bordered
          rowSelection={{
            selectedRowKeys: selectedDocuments.map(value => value.id),
            onChange: () => {
              // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            onSelect: (record, selected) => {
              // console.log(`onSelect record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows);
              if (selected === true) {
                addSelectedDocuments([record]);
              } else {
                removeSelectedDocuments([record]);
              }
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              // console.log('onSelectAll selected: ', selected, 'selectedRows: ', selectedRows, 'changeRows: ', changeRows);
              if (selected === true) {
                addSelectedDocuments(changeRows);
              } else {
                removeSelectedDocuments(changeRows);
              }
            },
            onSelectInvert: selectedRows => {
              // console.log('onSelectInvert selectedRows: ', selectedRows);
              removeSelectedDocuments(selectedRows);
            },
            getCheckboxProps: () => ({
              // Column configuration not to be checked
              // disabled: record.str_doc_status !== 'COMPLETE'
              // name: record.name,
            })
          }}
          expandedRowRender={this.showExpandedRow}
          expandedRowKeys={expandedRows}
          onExpandedRowsChange={rowKeys => {
            if (rowKeys.length > 0) {
              const selectedId = rowKeys[rowKeys.length - 1];

              fetchExpandedDetails(selectedId);

              // always show last expanded rows only
              setExpandedRows([selectedId]);
            } else {
              setExpandedRows([]);
            }
          }}
          onChange={this.useOnTableChange}
          title={() => (
            <>
              <Row type="flex" justify="space-between" gutter={[0, 16]}>
                <Col span={8}>
                  <Button
                    type="primary"
                    // disabled={!isValid}
                    loading={fetchIsLoading}
                    onClick={() => resetTimestamp()}
                    icon={<ReloadOutlined />}
                  />
                </Col>
              </Row>
            </>
          )}
          scroll={{ x: 950 }}
        />

        <div
          style={{
            position: 'fixed',
            bottom: '50px',
            right: '50px',
            zIndex: 9999
          }}
        >
          <Badge count={selectedDocuments.length}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              icon={<LaptopOutlined />}
              onClick={this.useShowWorkspace}
              loading={fetchIsLoading}
            />
          </Badge>
        </div>

        <GdsRcptIndexDialog />
      </Card>
    );
  }
}

GdsRcptIndexTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,
  resetTimestamp: PropTypes.func,
  goToPrint: PropTypes.func,
  goToPrintHandlingUnit: PropTypes.func,
  goToDocument: PropTypes.func,
  goToAudit: PropTypes.func,

  setWorkspaceVisible: PropTypes.func,

  addSelectedDocuments: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  documents: PropTypes.arrayOf(PropTypes.object),
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  sorts: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  filters: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),

  currentPage: PropTypes.number,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number,
  fetchIsLoading: PropTypes.bool,

  curSiteFlowId: PropTypes.number,
  fetchGdsRcptIndex: PropTypes.func,

  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  setExpandedRows: PropTypes.func,
  expandedIsLoading: PropTypes.bool,
  expandedDetails: PropTypes.arrayOf(PropTypes.object),
  fetchExpandedDetails: PropTypes.func
};

GdsRcptIndexTable.defaultProps = {
  intl: {},
  resetTimestamp() {},
  goToPrint() {},
  goToPrintHandlingUnit() {},
  goToDocument() {},
  goToAudit() {},

  setWorkspaceVisible() {},

  addSelectedDocuments() {},
  removeSelectedDocuments() {},
  documents: [],
  selectedDocuments: [],

  sorts: {},
  filters: {},

  currentPage: 1,
  pageSize: '20',
  total: 0,
  fetchIsLoading: false,

  curSiteFlowId: 0,
  fetchGdsRcptIndex() {},

  expandedRows: [],
  setExpandedRows() {},
  expandedIsLoading: false,
  expandedDetails: [],
  fetchExpandedDetails() {}
};

const mapStateToProps = state => ({
  timestamp: state.gdsRcptIndex.timestamp,
  workspaceIsVisible: state.gdsRcptIndex.workspaceIsVisible,

  documents: state.gdsRcptIndex.documents,
  selectedDocuments: state.gdsRcptIndex.selectedDocuments,

  sorts: state.gdsRcptIndex.sorts,
  filters: state.gdsRcptIndex.filters,

  currentPage: state.gdsRcptIndex.currentPage,
  pageSize: state.gdsRcptIndex.pageSize,
  total: state.gdsRcptIndex.total,
  fetchIsLoading: state.gdsRcptIndex.fetchIsLoading,

  curSiteFlowId: state.app.curSiteFlowId,
  expandedRows: state.gdsRcptIndex.expandedRows,
  expandedIsLoading: state.gdsRcptIndex.expandedIsLoading,
  expandedDetails: state.gdsRcptIndex.expandedDetails
});

const mapDispatchToProps = dispatch => ({
  resetTimestamp: (currentPage, sorts, filters, pageSize) =>
    dispatch(GdsRcptIndexActions.gdsRcptIndexResetTimestamp(currentPage, sorts, filters, pageSize)),
  goToPrint: document => dispatch(GdsRcptIndexActions.gdsRcptIndexGoToPrint(document)),
  goToPrintHandlingUnit: document =>
    dispatch(GdsRcptIndexActions.gdsRcptIndexGoToPrintHandlingUnit(document)),
  goToDocument: hdrId => dispatch(GdsRcptIndexActions.gdsRcptIndexGoToDocument(hdrId)),
  goToAudit: hdrId => dispatch(GdsRcptIndexActions.gdsRcptIndexGoToAudit(hdrId)),

  setWorkspaceVisible: boolean =>
    dispatch(GdsRcptIndexActions.gdsRcptIndexSetWorkspaceVisible(boolean)),

  addSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcptIndexActions.gdsRcptIndexAddSelectedDocuments(selectedDocuments)),
  removeSelectedDocuments: selectedDocuments =>
    dispatch(GdsRcptIndexActions.gdsRcptIndexRemoveSelectedDocuments(selectedDocuments)),

  fetchGdsRcptIndex: (currentPage, sorts, filters, pageSize) =>
    dispatch(
      GdsRcptIndexActions.gdsRcptIndexFetchGdsRcptIndex(currentPage, sorts, filters, pageSize)
    ),

  setExpandedRows: expandedRows =>
    dispatch(GdsRcptIndexActions.gdsRcptIndexSetExpandedRows(expandedRows)),
  fetchExpandedDetails: hdrId =>
    dispatch(GdsRcptIndexActions.gdsRcptIndexFetchExpandedDetails(hdrId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GdsRcptIndexTable));
