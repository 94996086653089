import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { Checkbox } from 'antd';

import WhsePerformanceChartActions from '../../Stores/WhsePerformanceChart/Actions';

const CheckboxGroup = Checkbox.Group;

class SelectAveragesCheckBox extends React.PureComponent {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
    // this.handleCheckAll = this.handleCheckAll.bind(this);
  }

  handleChange(list) {
    const { setVisibleKeys } = this.props;

    setVisibleKeys(list);
  }

  render() {
    const { visibleAverageKeys, intl } = this.props;
    const visibleAverageOptions = [
      { label: `${intl.formatMessage({ id: 'gds_rcpt_01' })}`, value: 'gds_rcpt_01' },
      { label: `${intl.formatMessage({ id: 'gds_rcpt_02' })}`, value: 'gds_rcpt_02' },
      { label: `${intl.formatMessage({ id: 'put_away_01' })}`, value: 'put_away_01' },
      { label: `${intl.formatMessage({ id: 'put_away_02' })}`, value: 'put_away_02' },
      { label: `${intl.formatMessage({ id: 'pick_list' })}`, value: 'pick_list' },
      { label: `${intl.formatMessage({ id: 'load_list' })}`, value: 'load_list' }
    ];

    return (
      <>
        <CheckboxGroup
          options={visibleAverageOptions}
          onChange={this.handleChange}
          value={visibleAverageKeys}
        />
      </>
    );
  }
}

SelectAveragesCheckBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object,

  setVisibleKeys: PropTypes.func,
  visibleAverageKeys: PropTypes.arrayOf(PropTypes.string)
};

SelectAveragesCheckBox.defaultProps = {
  intl: {},

  setVisibleKeys() {},
  visibleAverageKeys: []
};

const mapStateToProps = state => ({
  visibleAverageKeys: state.whsePerformanceChart.whsePerformanceChartVisibleAverageKeys
});

const mapDispatchToProps = dispatch => ({
  setVisibleKeys: visibleKeys =>
    dispatch(WhsePerformanceChartActions.whsePerformanceChartSetVisibleAverageKeys(visibleKeys))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectAveragesCheckBox));
