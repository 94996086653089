import { put, call, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import ApiService from '../Services/ApiService';
import AppActions from '../Stores/App/Actions';
import OutOfStockAnalysisReportActions, {
  OutOfStockAnalysisReportTypes
} from '../Stores/OutOfStockAnalysisReport/Actions';

const getAppStore = state => state.app;

export function* outOfStockAnalysisReportInitOutOfStockAnalysis() {
  try {
    yield put(OutOfStockAnalysisReportActions.outOfStockAnalysisReportReportLoading(true));

    const app = yield select(getAppStore);
    const getData = {};

    const result = yield call(
      ApiService.getApi, // function
      app.apiUrl,
      `salesReport/initOutOfStockAnalysis/${app.curSiteFlowId}`,
      app.token,
      getData,
      'multipart/form-data' // params
    );

    if (result.isSuccess === true) {
      yield put(
        OutOfStockAnalysisReportActions.outOfStockAnalysisReportInitOutOfStockAnalysisSuccess(
          result.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(OutOfStockAnalysisReportActions.outOfStockAnalysisReportReportLoading(false));
  }
}

export function* outOfStockAnalysisReportOutOfStockAnalysis({ formikBag, criteria }) {
  try {
    formikBag.setSubmitting(true);
    yield put(OutOfStockAnalysisReportActions.outOfStockAnalysisReportReportLoading(true));

    const objCriteria = {};
    if ('date' in criteria) {
      objCriteria.date = criteria.date;
    }
    if ('is_show_zero_balance' in criteria) {
      objCriteria.is_show_zero_balance = criteria.is_show_zero_balance;
    }
    if ('expiry_days' in criteria) {
      objCriteria.expiry_days = criteria.expiry_days;
    }
    if ('item_ids_select2' in criteria) {
      const values = criteria.item_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_01_ids_select2' in criteria) {
      const values = criteria.item_group_01_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_01_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_02_ids_select2' in criteria) {
      const values = criteria.item_group_02_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_02_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('item_group_03_ids_select2' in criteria) {
      const values = criteria.item_group_03_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.item_group_03_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bin_ids_select2' in criteria) {
      const values = criteria.storage_bin_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bin_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_row_ids_select2' in criteria) {
      const values = criteria.storage_row_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_row_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('storage_bay_ids_select2' in criteria) {
      const values = criteria.storage_bay_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.storage_bay_ids = values.map(entry => {
          return entry.value;
        });
      }
    }
    if ('location_ids_select2' in criteria) {
      const values = criteria.location_ids_select2;
      if (values !== null && values.length > 0) {
        objCriteria.location_ids = values.map(entry => {
          return entry.value;
        });
      }
    }

    let columns = [];
    if (criteria.columns.target.length > 0) {
      columns = criteria.columns.target.map(entry => {
        return entry.column;
      });
    }

    const app = yield select(getAppStore);
    const postData = {
      sorts: [],
      criteria: objCriteria,
      columns
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `salesReport/outOfStockAnalysis/${app.curSiteFlowId}`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      yield put(
        OutOfStockAnalysisReportActions.outOfStockAnalysisReportOutOfStockAnalysisSuccess(
          criteria,
          result.data.data
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    formikBag.setSubmitting(false);
    yield put(OutOfStockAnalysisReportActions.outOfStockAnalysisReportReportLoading(false));
  }
}

export function* outOfStockAnalysisReportFetchItemOptions({ search }) {
  try {
    yield put(OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemOptionLoading(true));

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `item/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemOptionLoading(false)
    );
  }
}

export function* outOfStockAnalysisReportFetchItemGroup01Options({ search }) {
  try {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup01OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup01/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup01OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup01OptionLoading(false)
    );
  }
}

export function* outOfStockAnalysisReportFetchItemGroup02Options({ search }) {
  try {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup02OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup02/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup02OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup02OptionLoading(false)
    );
  }
}

export function* outOfStockAnalysisReportFetchItemGroup03Options({ search }) {
  try {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup03OptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `itemGroup03/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup03OptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchItemGroup03OptionLoading(false)
    );
  }
}

export function* outOfStockAnalysisReportFetchDivisionOptions({ search }) {
  try {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchDivisionOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `division/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.name_01}`
      }));

      yield put(
        OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchDivisionOptionSuccess(options)
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchDivisionOptionLoading(false)
    );
  }
}

export function* outOfStockAnalysisReportFetchDeliveryPointOptions({ search }) {
  try {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchDeliveryPointOptionLoading(true)
    );

    const app = yield select(getAppStore);
    const postData = {
      search,
      filters: []
    };

    const result = yield call(
      ApiService.postApi, // function
      app.apiUrl,
      `deliveryPoint/select2`,
      app.token,
      postData
    );

    if (result.isSuccess === true) {
      const options = result.data.data.map(d => ({
        value: d.id,
        label: `${d.code} ${d.desc_01}`
      }));

      yield put(
        OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchDeliveryPointOptionSuccess(
          options
        )
      );
    } else if (result.isTokenExpired === true) {
      yield put(AppActions.appTokenExpired(result.message));
    } else if (result.isPasswordExpired === true) {
      yield put(AppActions.appPasswordExpired(result.message));
    } else {
      yield call(notification.error, {
        message: result.message,
        duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
      });
    }
  } catch (error) {
    yield call(notification.error, {
      message: error.message,
      duration: parseInt(process.env.REACT_APP_ERROR_MESSAGE_DURATION, 10)
    });
  } finally {
    yield put(
      OutOfStockAnalysisReportActions.outOfStockAnalysisReportFetchDeliveryPointOptionLoading(false)
    );
  }
}

export const saga = [
  takeLatest(
    OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_INIT_OUT_OF_STOCK_ANALYSIS,
    outOfStockAnalysisReportInitOutOfStockAnalysis
  ),
  takeLatest(
    OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_OUT_OF_STOCK_ANALYSIS,
    outOfStockAnalysisReportOutOfStockAnalysis
  ),
  takeLatest(
    OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_OPTIONS,
    outOfStockAnalysisReportFetchItemOptions
  ),
  takeLatest(
    OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP01_OPTIONS,
    outOfStockAnalysisReportFetchItemGroup01Options
  ),
  takeLatest(
    OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP02_OPTIONS,
    outOfStockAnalysisReportFetchItemGroup02Options
  ),
  takeLatest(
    OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_ITEM_GROUP03_OPTIONS,
    outOfStockAnalysisReportFetchItemGroup03Options
  ),

  takeLatest(
    OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_DIVISION_OPTIONS,
    outOfStockAnalysisReportFetchDivisionOptions
  ),
  takeLatest(
    OutOfStockAnalysisReportTypes.OUT_OF_STOCK_ANALYSIS_REPORT_FETCH_DELIVERY_POINT_OPTIONS,
    outOfStockAnalysisReportFetchDeliveryPointOptions
  )
];
